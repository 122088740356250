import { render, staticRenderFns } from "./LibCollapseIcon.vue?vue&type=template&id=14b4f354&scoped=true"
import script from "./LibCollapseIcon.vue?vue&type=script&lang=js"
export * from "./LibCollapseIcon.vue?vue&type=script&lang=js"
import style0 from "./LibCollapseIcon.vue?vue&type=style&index=0&id=14b4f354&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b4f354",
  null
  
)

export default component.exports