import Layouts from "/library/layouts/index.vue";
import feedback from "@/assets/router-icon/feedback.png";
import feedback_on from "@/assets/router-icon/feedback-on.png";

export default {
    path: '/problemFeedback',
    name: 'ProblemFeedback',
    component: Layouts,
    redirect: "/problemFeedback/feedbackList",
    meta: {
        title: "统计",
        icon: feedback,
        icon_on: feedback_on
    },
    children: [
        {
            path: 'feedbackList',
            name: 'FeedbackList',
            component: () => import("@/views/ProblemFeedback/FeedbackList.vue"),
            meta: {
                title: "全部统计",
            },
        },
    ]
}
